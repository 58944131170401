import React from 'react' // , { useState } from 'react'
import PropTypes from 'prop-types'

import Layout from '@components/layout'
import LateralIcons from '@components/lateralIcons'
import Cards from '@components/cards'
import Container from '@components/container'
// import BrandCarrousel from '@components/brandCarrousel'
import { Heading } from '@components/headings'
import FadeInUp from '@components/fadeInUp'
import Content from '@components/content'
import Icon from '@components/icon'
import FadeCarrousel from '@components/fadeCarrousel'
import ContactUsCta from '@components/ContactUsCta'
import Partners from '@components/partners'
import { Breakpoints, classNames } from '@utils'
import styles from './home.module.scss'

const Home = props => {
  const { location, pageContext } = props

  // const [visible, setVisivility] = useState(false)
  const visible = true

  // let visible = false

  // window.addEventListener('scroll', () => {
  //   const top = window.pageYOffset || document.documentElement.scrollTop
  //   if (top > 400) {
  //     // visible = true
  //     setVisivility(true)
  //   } else {
  //     setVisivility(false)
  //   }
  // })


  return (
    <Layout
      location={location}
      pageContext={pageContext}
      contentStyle={styles.contentStyle}
    >
      {/* <BrandCarrousel
        items={pageContext.brandCarrousel}
        className={styles.brandCarrousel}
      /> */}
      <Container>

        <Heading className={classNames(styles.heading, styles.introducingHeader)}>
          {pageContext.headings[1].title}
        </Heading>
        <div className={styles.mainContent}>
          <Content
            {...pageContext.mainContents[0].content}
            className={styles.mainContentTextFirst}
          />
          <FadeInUp>
            <Icon
              {...pageContext.mainContents[0].images}
              className={styles.mainContentImageFirst}
            />
          </FadeInUp>
        </div>
        <div className={styles.mainContent}>
          <FadeInUp>
            <Icon
              {...pageContext.mainContents[1].images}
              className={styles.mainContentImageSecond}
            />
          </FadeInUp>
          <Content
            {...pageContext.mainContents[1].content}
            className={styles.mainContentTextSecond}
          />
        </div>
        <Heading className={styles.heading}>
          {pageContext.headings[0].title}
        </Heading>
        <Breakpoints>
          {breakpoint => {
            const lateralIcons = ['xs', 'sm', 'md'].includes(breakpoint)
            return !lateralIcons ? (
              <LateralIcons
                right
                visible={visible}
                rigthIconContainer={styles.rightIcons}
                leftIconContainer={styles.leftIcons}
                rightIconStyle={styles.rightIconsItem}
                leftIconStyle={styles.leftIconsItem}
              />
            ) : null
          }}
        </Breakpoints>
        <Cards
          cards={pageContext.cards}
          className={styles.cards}
          onFinishAnimation={() => {}}
        />
      </Container>
      <FadeCarrousel
        {...pageContext.bannerCarrousel}
        className={styles.fadeCarrousel}
      />
      <Partners {...pageContext.partners} />
      <ContactUsCta {...pageContext.bannerContactUs} />
    </Layout>
  )
}

Home.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    brandCarrousel: PropTypes.array,
    cards: PropTypes.array,
    headings: PropTypes.array,
    mainContents: PropTypes.array,
    bannerCarrousel: PropTypes.object,
    bannerContactUs: PropTypes.shape({
      text: PropTypes.string,
      buttonText: PropTypes.string
    }),
    partners: PropTypes.object
  })
}

export default Home
