import React from 'react'
import PropTypes from 'prop-types'

import Button from '@components/button'
import Field from '@components/field'

const FormStep = props => {
  const { fields, nextLabel, onNext, formRef, actionUrl } = props
  return (
    <>
      <form action={actionUrl} method="post" ref={formRef}>
      {fields.map(field => (
        <div key={field.name}>
          <Field {...field} />
        </div>
      ))}
      <Button onClick={onNext}>{nextLabel}</Button>
      </form>
    </>
  )
}

FormStep.propTypes = {
  actionUrl: PropTypes.string,
  fields: PropTypes.array,
  nextLabel: PropTypes.string,
  onNext: PropTypes.func,
  formRef: PropTypes.any,
}

export default FormStep
