import React from 'react'
import PropTypes from 'prop-types'

import Button from '@components/button'
import styles from './ButtonGroup.module.scss'

const ButtonGroup = props => {
  const { options, onSelect } = props
  return (
    <ul className={styles.buttonGroup}>
      {options.map(({ id, label }) => (
        <li key={id}>
          <Button onClick={() => onSelect(id)}>{label}</Button>
        </li>
      ))}
    </ul>
  )
}

ButtonGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func,
}

export default ButtonGroup
