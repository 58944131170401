/* eslint-disable react/no-array-index-key, jsx-a11y/media-has-caption, no-use-before-define */

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import styles from './Ecosystem.module.scss'
import { Heading, SubHeading } from '../headings'
import { classNames } from '@utils'

import PublishersImage from '../../assets/images/advertisers/logos2x.png'

const Ecosystem = props => {
  const {
    title,
    saferSocial,
    betterTargeting,
    smarterCreative,
    reach,
  } = props

  const [visible, setVisible] = useState(false)
  const imagesRef = useRef(null)

  function handleScroll() {
    const { bottom } = imagesRef.current.getBoundingClientRect()
    if (bottom < window.innerHeight) {
      setVisible(true)
      removeEvent()
    }
  }

  function removeEvent() {
    if (window) {
      window.removeEventListener('scroll', handleScroll)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => removeEvent()
  }, [])

  return (
    <section>
      <Heading className={styles.title}>{title}</Heading>
      <div className={styles.saferSocial}>
        <div>
          <SubHeading>{saferSocial.title}</SubHeading>
          <p className={styles.description}>{saferSocial.text}</p>
        </div>
        <div className={styles.saferSocialImage}>
          {
            saferSocial.video
              ? (
                <video style={{ width: '100%' }} autoPlay loop>
                  <source src={saferSocial.video} type="video/mp4" />
                </video>
              )
              : <img src={saferSocial.image} className={styles.image} alt="" />
          }
        </div>
      </div>

      <div className={styles.betterTargetingWrapper}>
        <div>
          {
            betterTargeting.video
              ? (
                <video style={{ width: '100%' }} autoPlay loop>
                  <source src={betterTargeting.video} type="video/mp4" />
                </video>
              )
              : <img src={betterTargeting.image} className={styles.image} alt="" />
          }
        </div>
        <div>
          <SubHeading>{betterTargeting.title}</SubHeading>
          <ol className={styles.list}>
            {betterTargeting.how.map((item, i) => (
              <li key={i}>
                <h4 className={styles.itemTitle}>{item.title}</h4>
                <p className={styles.text}>{item.text}</p>
              </li>
            ))}
          </ol>
        </div>
      </div>

      <div className={styles.imagesGroup}>
        <div
          className={classNames(styles.imagesGroupWrap, (visible && styles.visible))}
          ref={imagesRef}
        >
          {smarterCreative.images.map((image, i) => (
            <img key={i} src={image} alt="" />
          ))}
        </div>
      </div>
      <div className={styles.smarterCreative}>
        <SubHeading>{smarterCreative.title}</SubHeading>
        <p className={styles.text}>{smarterCreative.text}</p>
      </div>

      <div className={styles.reach}>
        <div className={styles.reachTextWrapper}>
          <SubHeading className={styles.reachText}>{reach.title}</SubHeading>
        </div>
        <div className={styles.reachImageWrapper}>
          <img src={PublishersImage} className={styles.reachImage} alt="" />
        </div>
      </div>
    </section>
  )
}

Ecosystem.propTypes = {
  title: PropTypes.string.isRequired,
  saferSocial: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.string,
    video: PropTypes.string,
  }),
  betterTargeting: PropTypes.shape({
    title: PropTypes.string,
    how: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
      }),
    ),
    image: PropTypes.string,
    video: PropTypes.string,
  }),
  smarterCreative: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
  }),
  reach: PropTypes.shape({
    title: PropTypes.string,
  }),
}

export default Ecosystem
