import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Icon from '@components/icon'
import styles from './partnering.module.scss'

const Partnering = props => {
  const { text, variants, icon } = props
  const [key, setKey] = useState(0)

  useEffect(() => {
    const visibilityInterval = setInterval(
      () => {
        setKey(_word => ((_word + 1) % variants.length))
      }, 3000
    )
    return () => {
      clearInterval(visibilityInterval)
    }
  }, [])

  return (
    <div className={styles.container}>
      <p>
        {text}
        {' '}
        <span className={styles.border}>
          &nbsp;
          {variants.map((word, i) => (
            <em key={word} className={i === key ? styles.visible : styles.noVisible}>{word}.</em>
          ))}
        </span>
      </p>
      <Icon {...icon} className={styles.icon} />
    </div>
  )
}

Partnering.propTypes = {
  text: PropTypes.string,
  variants: PropTypes.arrayOf(
    PropTypes.string
  ),
  icon: PropTypes.object
}

export default Partnering
