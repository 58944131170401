import React from 'react'
import PropTypes from 'prop-types'

import { Heading } from '@components/headings'
import ReasonItem from './ReasonItem'
import { Breakpoints } from '@utils'
import styles from './reasons.module.scss'

const Reasons = props => {
  const { items, title } = props

  const reason = (items.length > 0) ?
    items.map((item, i) => (
      <Breakpoints key={item.ID}>
      {
        breakpoint => {
          const incomplete = ['sm', 'xs'].includes(breakpoint)
          return (!incomplete || (i + 1 < items.length - 1)) ?
              <ReasonItem key={item.ID} {...item} />
            :
            null
        }
      }
      </Breakpoints>
    ))
    :
    null

  return (
    <div className={styles.container}>
      <Heading>{title}</Heading>
      <div className={styles.wraper}>
        {reason}
      </div>
    </div>
  )
}

Reasons.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      icon: PropTypes.object,
      title: PropTypes.string,
      content: PropTypes.string
    })
  )
}

export default Reasons
