import React from 'react'
import PropTypes from 'prop-types'

import ButtonGroup from '@components/buttonGroup'
import styles from './getStarted.module.scss'

const FirstStep = props => {
  const { text, options, onSelect } = props
  return (
    <>
      <h1 className={styles.title}>{text}</h1>
      <ButtonGroup options={options} onSelect={onSelect} />
    </>
  )
}

FirstStep.propTypes = {
  text: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  onSelect: PropTypes.func.isRequired,
}

export default FirstStep
