import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Swiper, EffectFade, Autoplay } from 'swiper/js/swiper.esm'
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom'
import 'react-id-swiper/lib/styles/scss/swiper.scss'
import 'swiper/components/effect-fade/effect-fade.scss'
import Icon from '@components/icon'
import styles from './fadeCarrousel.module.scss'
import CarrouselItem from './CarrouselItem'

const FadeCarrousel = props => {
  const { items } = props

  const [swiper, updateSwiper] = useState(null)
  const [currentIndex, updateCurrentIndex] = useState(1)

  const params = {
    Swiper,
    modules: [EffectFade, Autoplay],
    fadeEffect: {
      crossFade: true
    },
    autoplay: {
      delay: 8000,
    },
    effect: 'fade',
    width: 750,
    speed: 600,
    loop: true,
    getSwiper: updateSwiper,
  }
  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }

  useEffect(() => {
    if (swiper !== null) {
      swiper.on('slideChange', () => updateCurrentIndex(swiper.realIndex + 1))
    }

    return () => {
      if (swiper !== null) {
        swiper.off('slideChange', () => updateCurrentIndex(swiper.realIndex + 1))
      }
    }
  }, [swiper])
  return (
    <div className={styles.container}>
      <div className={styles.carousel}>
        <ReactIdSwiperCustom {...params}>
          {items && items.map(item => <div key={item.id}><CarrouselItem {...item} /></div>)}
        </ReactIdSwiperCustom>
        <div className={styles.btnWrapper}>
          <div className={styles.indexWraper}>
            <p className={styles.index}>
              <span className={styles.indexCurrent}>{currentIndex}</span>
              /
              <span className={styles.indexTotal}>{items.length}</span>
            </p>
          </div>
          <div>
            <button className={styles.btn} onClick={goPrev} type="button">
              <Icon name="arrowLeft.svg" className={styles.btnArrow} />
            </button>
            <button className={styles.btn} onClick={goNext} type="button">
              <Icon name="arrow.svg" className={styles.btnArrow} />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

FadeCarrousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        name: PropTypes.string
      }),
      text: PropTypes.shape({
        name: PropTypes.string,
        quote: PropTypes.string,
        ocupation: PropTypes.string,
      })
    })
  )
}

export default FadeCarrousel
