import React from 'react'
import PropTypes from 'prop-types'

import styles from './WhySocialEmbeds.module.scss'

const WhySocialEmbedsItem = props => {
  const { label, value } = props
  return (
    <p className={styles.advantagesItem}>
      <em className={styles.value}>{value}</em>
      <span>{label}</span>
    </p>
  )
}

WhySocialEmbedsItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
}

export default WhySocialEmbedsItem
