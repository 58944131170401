import React from 'react'
import PropTypes from 'prop-types'

import styles from './carrouselItem.module.scss'
import { classNames } from '@utils'


const CarrouselItem = props => {
  const { text: { quote, name, ocupation }, image } = props

  return (
    <div className={styles.container}>
      <img src={`/images/home/${image.name}`} className={styles.icon} alt="" />
      <div className={styles.textWraper}>
        <blockquote className={classNames(styles.textQuote, styles.text)}>{quote}</blockquote>
        <div className={styles.textData}>
          <p className={classNames(styles.textName, styles.text)}>{name && name.toUpperCase()}</p>
          <p className={classNames(styles.textOcupation, styles.text)}>{ocupation}</p>
        </div>
      </div>
    </div>
  )
}

CarrouselItem.propTypes = {
  image: PropTypes.shape({
    name: PropTypes.string
  }),
  text: PropTypes.shape({
    name: PropTypes.string,
    quote: PropTypes.string,
    ocupation: PropTypes.string,
  })
}

export default CarrouselItem
