import React from 'react'
import PropTypes from 'prop-types'

import styles from './getStarted.module.scss'

const FormCaption = props => {
  const { text, onBack } = props
  return (
    <>
      <a
        role="button"
        tabIndex={0}
        onClick={onBack}
        onKeyPress={onBack}
        className={styles.back}
      >
        &lt; Back
      </a>
      <h2 className={styles.caption}>{text}</h2>
    </>
  )
}

FormCaption.propTypes = {
  text: PropTypes.string,
  onBack: PropTypes.func,
}

export default FormCaption
