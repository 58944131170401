import React from 'react'
import PropTypes from 'prop-types'

import Button from '@components/button'
import styles from './getStarted.module.scss'

const Done = props => {
  const { text } = props
  return (
    <div>
      <h1 className={styles.done}>{text}</h1>
      <Button to="/">Back to Home</Button>
    </div>
  )
}

Done.propTypes = {
  text: PropTypes.string.isRequired
}

export default Done
