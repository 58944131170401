import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@components/icon'
import styles from './reasonItem.module.scss'

const ReasonItem = props => {
  const { icon, title, content } = props

  return (
    <div className={styles.container}>
      <Icon name={icon.name} alt={icon.alt} className={styles.icon} />
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.content}>{content}</p>
    </div>
  )
}

ReasonItem.propTypes = {
  icon: PropTypes.shape({
    name: PropTypes.string,
    alt: PropTypes.string
  }),
  title: PropTypes.string,
  content: PropTypes.string
}

export default ReasonItem
