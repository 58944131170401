import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import styles from './WhySocialEmbeds.module.scss'
import { Heading } from '../headings'
import { classNames } from '@utils'
import WhySocialEmbedsItem from './WhySocialEmbedsItem'

const WhySocialEmbeds = props => {
  const { title, image, advantages } = props
  const [visible, setVisibility] = useState(false)
  const inputEl = useRef(null)

  function handleScroll() {
    const { height, bottom } = inputEl.current.getBoundingClientRect()
    const WindowTopPosition = window.pageYOffset
    const offset = (bottom - height) | 0
    setVisibility(offset < WindowTopPosition)
  }

  function removeEvent() {
    if (window) {
      window.removeEventListener('scroll', handleScroll)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => removeEvent()
  }, [])

  return (
    <section className={styles.section} ref={inputEl}>
      <Heading className={styles.title}>{title}</Heading>
      <div className={styles.wrapper}>
        <div
          className={
            classNames(
              styles.imageContainer,
              visible && styles.visible,
              !visible && styles.invisible
            )
          }
        >
          <img src={image} className={styles.image} alt="" />
        </div>
        <div className={styles.advantages}>
          {advantages.map(item => (
            <WhySocialEmbedsItem key={item.label} {...item} />
          ))}
        </div>
      </div>
    </section>
  )
}

WhySocialEmbeds.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  advantages: PropTypes.array
}

export default WhySocialEmbeds
