import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@components/icon'
import styles from './socialIntegration.module.scss'

const SocialIntegration = props => {
  const { icon, title } = props

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{title}</h3>
      <Icon name={icon.name} alt={icon.alt} className={styles.icon} />
    </div>
  )
}

SocialIntegration.propTypes = {
  icon: PropTypes.shape({
    name: PropTypes.string,
    alt: PropTypes.string
  }),
  title: PropTypes.string
}

export default SocialIntegration
