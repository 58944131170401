import React from 'react'
import PropTypes from 'prop-types'

import styles from './itemCard.module.scss'
import { classNames } from '@utils'

const ItemCard = props => {
  const { title, content, percentage, footer, className } = props
  return (
    <div className={classNames(styles.card, className)}>
      <p className={classNames(styles.title, styles.text)}>{title}</p>
      <div className={styles.line} />
      <div className={styles.textWrapper}>
        <h3 className={classNames(styles.textTitle, styles.text)}>
          {percentage}
        </h3>
        <p className={classNames(styles.textContent, styles.text)}>{content}</p>
      </div>
      <p className={classNames(styles.textFooter, styles.text)}>{footer}</p>
    </div>
  )
}

ItemCard.propTypes = {
  content: PropTypes.string,
  percentage: PropTypes.string,
  footer: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
}

export default ItemCard
