import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@components/icon'
import styles from './Field.module.scss'

const Field = props => {
  const {
    label,
    name,
    type,
    icon,
    options,
  } = props

  let input
  if (type === 'textarea') {
    input = (
      <textarea
        type={type}
        name={name}
        placeholder={label}
        rows={5}
        cols={24}
        className={styles.textField}
      />
    )
  } else if (type === 'select') {
    const selectOptions = []
    selectOptions.push(<option key="placeholder" value="" disabled hidden>{label}</option>)
    options.forEach(option => {
      selectOptions.push(<option key={option} value={option}>{option}</option>)
    })

    input = (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <select
        required
        name={name}
        className={styles.textField}
        defaultValue=""
      >

          { selectOptions }

      </select>
    )
  } else if (type === 'reportid') {
    input = (
      <input
        type="hidden"
        name={name}
        value={Math.random().toString(36).substr(2)}
      />
    )
  } else {
    input = (
      <input
        type={type}
        name={name}
        placeholder={label}
        className={styles.textField}
      />
    )
  }

  if (type === 'reportid') {
    return input
  }

  return (
    <div className={styles.fieldContainer}>
      <Icon name={icon} className={styles.icon} />
      {input}
    </div>
  )
}

Field.defaultProps = {
  type: 'text',
}

Field.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
}

export default Field
