import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { classNames } from '@utils'
import styles from './fadeInUp.module.scss'

const FadeInUp = props => {
  const { children, className } = props

  const [visible, setVisibility] = useState(false)
  const inputEl = useRef(null)

  function handleScroll() {
    const { height, bottom } = inputEl.current.getBoundingClientRect()
    const WindowTopPosition = window.pageYOffset
    const offset = (bottom - height) | 0
    setVisibility(offset < WindowTopPosition)
  }

  function removeEvent() {
    if (window) {
      window.removeEventListener('scroll', handleScroll)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => removeEvent()
  }, [])


  return (
    <div
      className={
        classNames(
          visible && styles.visible,
          !visible && styles.invisible,
          className
        )
      }
      ref={inputEl}
    >
      { children }
    </div>
  )
}

FadeInUp.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string
}

export default FadeInUp
