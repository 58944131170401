import React, { useState, useEffect, useRef } from 'react'

import Icon from '@components/icon'
import { classNames } from '@utils'
import styles from './news.module.scss'

const News = () => {
  const [visible, setVisibility] = useState(false)
  const inputEl = useRef(null)

  function handleScroll() {
    const { height, bottom } = inputEl.current.getBoundingClientRect()
    const WindowTopPosition = window.pageYOffset
    const offset = (bottom - height) | 0
    setVisibility(offset < WindowTopPosition)
  }

  function removeEvent() {
    if (window) {
      window.removeEventListener('scroll', handleScroll)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => removeEvent()
  }, [])

  return (
    <div className={styles.container} ref={inputEl}>
      <div className={styles.textWraper}>
        <p>People used to talk about the news on social media…</p>
        <p><span>Now social media is the news.</span></p>
      </div>
      <Icon
        name="social_media.png"
        alt="social_media"
        className={
          classNames(
            styles.icon,
            visible && styles.visible,
            !visible && styles.invisible,
          )
        }
      />
    </div>
  )
}

export default News
