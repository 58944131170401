import React from 'react'
import PropTypes from 'prop-types'

import Layout from '@components/layout'
import { WhySocialEmbeds, Ecosystem } from '@components/advertisers'
import FeaturedItems from '@components/FeaturedItems'
import Partners from '@components/partners'
// import BannerWithImage from '@components/bannerWithImage'
import ContactUsCta from '@components/ContactUsCta'
import styles from './advertisers.module.scss'

const Advertisers = props => {
  const { location, pageContext } = props
  return (
    <Layout
      location={location}
      pageContext={pageContext}
      imageStyle={styles.image}
    >
      <WhySocialEmbeds {...pageContext.why} />
      <Ecosystem {...pageContext.ecosystem} />
      <FeaturedItems {...pageContext.activationAgnostic} />
      <Partners {...pageContext.partners} />
      {/* <BannerWithImage {...pageContext.bannerWithImage} /> */}
      <ContactUsCta {...pageContext.contactUsCta} />
    </Layout>
  )
}

Advertisers.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object
}

export default Advertisers
