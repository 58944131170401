import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import styles from './cards.module.scss'
import ItemCard from './ItemCard'
import { classNames } from '@utils'

const Cards = props => {
  const { cards, className, onFinishAnimation } = props

  const [visible, setVisibility] = useState(false)
  const inputEl = useRef(null)

  function handleScroll() {
    const { height, bottom } = inputEl.current.getBoundingClientRect()


    const WindowTopPosition = window.pageYOffset
    const offset = (bottom - height - 500) | 0

    // console.log(inputEl);

    setVisibility(offset < WindowTopPosition)
    onFinishAnimation(offset < WindowTopPosition)
  }

  function removeEvent() {
    if (window) {
      window.removeEventListener('scroll', handleScroll)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => removeEvent()
  }, [])

  return (
    <div className={classNames(styles.cards, className)} ref={inputEl}>
      {cards &&
        cards.map(card => (
          <ItemCard
            key={card.ID}
            percentage={card.percentage}
            title={card.title}
            content={card.content}
            footer={card.footer}
            className={
              visible ? styles[`cardEffect${0}`] : styles[`cardInvisible${0}`]
            }
          />
        ))}
    </div>
  )
}

Cards.propTypes = {
  className: PropTypes.string,
  onFinishAnimation: PropTypes.func,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      percentage: PropTypes.string,
      content: PropTypes.string,
      footer: PropTypes.string,
      title: PropTypes.string,
    })
  )
}

export default Cards
