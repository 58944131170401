/* eslint-disable max-len */
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import SEO from '@components/seo'
import Container from '@components/container'
import { HeaderNav } from '@components/header'
import { FirstStep, FormCaption, FormStep, Done } from '@components/getStarted'
import axios from 'axios'
// import { unwatchFile } from 'fs'
import styles from './getStarted.module.scss'

const intelligenceApiEndpoint = '//intelligence.rhombusads.com'

let submitting = false

async function submitJob(submitted) {
  submitting = true
  try {
    await axios({
      url: `${intelligenceApiEndpoint}/briefReportData/submit`,
      method: 'POST',
      params: {
        domain: submitted.domain,
        report_id: submitted.report_id,
        version: '1',
        email: submitted.email,
        select: submitted.select,
        sessionGuestId: '587b6915f6c25e3b43f091212cccb1ee7e90d5ef2afdb0230869bd34864260d1'
      }
    })
  } catch (err) {
    // console.log('Error submitting job: ', err)
  }
  submitting = false
}

async function reportDrip() {
  // eslint-disable-next-line no-undef
  const submitted = { ...JSON.parse(localStorage.getItem('submitted')) }
  if (!submitted) {
    return
  }
  try {
    await axios({
      url: `${intelligenceApiEndpoint}/briefReportData/report-drip`,
      method: 'POST',
      params: {
        report_id: submitted.report_id,
        version: '1',
        sessionGuestId: '587b6915f6c25e3b43f091212cccb1ee7e90d5ef2afdb0230869bd34864260d1'
      }
    })
    // eslint-disable-next-line no-undef
    localStorage.removeItem('submitted')
  } catch (err) {
    // TODO: error handling
  }
}


const getStarted = props => {
  const { location, pageContext } = props

  let state = null
  if (location.hash.includes('advertiser')) {
    state = 'advertiser'
  } else if (location.hash.includes('publisher')) {
    state = 'publisher'
  } else if (location.hash.includes('thanks')) {
    state = 'done'
  }

  const [userType, setUserType] = useState(state)
  const [step, setStep] = useState(0)

  const formEl = useRef(null)


  let content
  let form
  if (userType && userType !== 'done') {
    if (step === pageContext.userType[userType].steps.length) {
      content = <Done text={pageContext.userType[userType].done} />
    } else {
      const submit = step === pageContext.userType[userType].steps.length - 1
      content = (
        <FormCaption
          {...pageContext.userType[userType].steps[step]}
          onBack={() => (step === 0 ? setUserType(null) : setStep(step - 1))}
        />
      )
      form = (
        <FormStep
          {...pageContext.userType[userType].steps[step]}
          actionUrl={pageContext.userType[userType].actionUrl}
          nextLabel={submit ? 'Submit' : 'Next'}
          formRef={formEl}
          onNext={() => {
            if (userType !== 'publisher') {
              formEl.current.submit()
              return
            }
            if (submitting) {
              return
            }
            const email = formEl.current.elements['fields[email]'].value
            const url = formEl.current.elements['fields[url]'].value
            const reportId = formEl.current.elements['fields[reportid]'].value
            let pageViews = formEl.current.elements['fields[MonthlyPageViews]'].value
            if (pageViews.endsWith(' 5MM')) {
              pageViews = 5
            } else if (pageViews.endsWith(' 10MM')) {
              pageViews = 10
            } else if (pageViews.endsWith(' 25MM')) {
              pageViews = 25
            } else if (pageViews.endsWith(' 100MM')) {
              pageViews = 100
            } else {
              pageViews = 200
            }

            if (!email.length || !url.length) {
              // TODO: validation
              return
            }
            const data = { domain: url, select: pageViews, email, report_id: reportId }
            // eslint-disable-next-line no-undef
            localStorage.setItem('submitted', JSON.stringify(data))
            submitJob({ domain: url, select: pageViews, email, report_id: reportId }).then(() => {
              formEl.current.submit()
            })
          }}
        />
      )
    }
  } else if (state === 'done') {
    content = <Done text={pageContext.userType.other.done} />

    reportDrip().then(() => {})
  } else {
    content = <FirstStep {...pageContext.start} onSelect={setUserType} />
  }

  return (
    <div className={styles.layout}>
      <SEO location={location} pageContext={pageContext} />
      <HeaderNav inverted staticPos />
      <main className={styles.main}>
        <Container className={styles.container}>
          <div className={styles.caption}>
            {content}
          </div>
          {form && (
            <div className={styles.formWrapper}>
              {form}
            </div>
          )}
        </Container>
      </main>
    </div>
  )
}


getStarted.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    title: PropTypes.string,
    start: PropTypes.object,
    userType: PropTypes.object,
  }).isRequired,
}

export default getStarted
