import React from 'react'
import PropTypes from 'prop-types'

import pageData from '../../content/pages/home.yml'
// eslint-disable-next-line import/no-dynamic-require
const Template = require(`@templates/${pageData.template}`).default

const Pages = props => {
  const { pageContext } = props
  const templateProps = {
    ...props,
    pageContext: {
      pageContext,
      ...pageData
    }
  }

  return <Template {...templateProps} />
}

Pages.propTypes = {
  pageContext: PropTypes.object
}

export default Pages
