import React from 'react'
import PropTypes from 'prop-types'

import Layout from '@components/layout'

const Page = props => {
  const { location, pageContext } = props
  const { title, subtitle, content } = pageContext

  return (
    <Layout location={location} pageContext={pageContext}>
      <div>{title}</div>
      <div>{subtitle}</div>
      <div>{content}</div>
    </Layout>
  )
}

Page.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default Page
