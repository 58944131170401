import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@components/icon'
import { classNames } from '@utils'
import styles from './lateralIcons.module.scss'

const LateralIcons = props => {
  const { rigthIconContainer, leftIconContainer, visible, rightIconStyle, leftIconStyle } = props

  return (
    <div>
      <div
        className={
          classNames(
            styles.container,
            rigthIconContainer,
            styles.wrapperRight
          )
        }
      >
        <Icon
          name="rigthSideIcons.png"
          className={
            classNames(
              rightIconStyle,
              styles.containerRight,
              visible && styles.containerRightVisible,
              rigthIconContainer
            )
          }
        />
      </div>
      <div
        className={
          classNames(
            styles.container,
            leftIconContainer,
            styles.wrapperLeft
          )
        }
      >
        <Icon
          name="leftSideIcons.png"
          className={
            classNames(
              leftIconStyle,
              styles.containerLeft,
              visible && styles.containerLeftVisible,
              leftIconContainer
            )
          }
        />
      </div>
    </div>
  )
}

LateralIcons.propTypes = {
  rigthIconContainer: PropTypes.string,
  leftIconContainer: PropTypes.string,
  visible: PropTypes.bool,
  rightIconStyle: PropTypes.string,
  leftIconStyle: PropTypes.string
}

export default LateralIcons
