import React from 'react'
import PropTypes from 'prop-types'

import Layout from '@components/layout'
import Container from '@components/container'
import SocialIntegration from '@components/socialIntegration'
import Icon from '@components/icon'
import News from '@components/news'
import { Heading } from '@components/headings'
import Content from '@components/content'
import Partnering from '@components/partnering'
import Reasons from '@components/reasons'
// import BrandCarrousel from '@components/brandCarrousel'
// import BannerWithImage from '@components/bannerWithImage'
import ContactUsCta from '@components/ContactUsCta'
import FadeInUp from '@components/fadeInUp'
import { Breakpoints } from '@utils'
import styles from './publishers.module.scss'

const Publishers = props => {
  const { location, pageContext } = props

  return (
    <Layout
      location={location}
      pageContext={pageContext}
      contentStyle={styles.contentStyle}
    >
      <Container className={styles.container}>
        <SocialIntegration {...pageContext.socialIntegration} />
        <Breakpoints>
        {
          breakpoint => {
            const illustration = ['xs', 'sm'].includes(breakpoint)
            return (
              (illustration && pageContext.subHeader.image)
                ?
                (
                  <div className={styles.illustrationWraper}>
                    <img src={pageContext.subHeader.image} alt="" className={styles.illustration} />
                  </div>
                )
                :
                null
            )
          }
        }
        </Breakpoints>
        <News />
        <Heading className={styles.heading}>{pageContext.headings[0].title}</Heading>
        <div className={styles.mainContent}>
          <Content
            {...pageContext.mainContents[0].content}
            className={styles.mainContentTextFirst}
          />
          <FadeInUp className={styles.mainContentImageFirst}>
            <Icon
              {...pageContext.mainContents[0].images}
              className={styles.mainContentImageFirst}
            />
          </FadeInUp>
        </div>
        <div className={styles.mainContent}>
          <FadeInUp className={styles.mainContentImageSecond}>
            <Icon
              {...pageContext.mainContents[1].images}
              className={styles.mainContentImageSecond}
            />
          </FadeInUp>
          <Content
            {...pageContext.mainContents[1].content}
            className={styles.mainContentTextSecond}
          />
        </div>
        <Partnering {...pageContext.partnering} />
      </Container>
      <Reasons {...pageContext.reasons} />
      {/* <Heading className={styles.headingSecond}>{pageContext.headings[1].title}</Heading>
      <BrandCarrousel
        items={pageContext.brandCarrousel}
        className={styles.brandCarrousel}
      /> */}
      {/* <BannerWithImage {...pageContext.bannerWithImage} /> */}
      <ContactUsCta {...pageContext.bannerContactUs} />
    </Layout>
  )
}

Publishers.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    socialIntegration: PropTypes.object,
    bannerWithImage: PropTypes.object,
    headings: PropTypes.array,
    reasons: PropTypes.object,
    mainContents: PropTypes.array,
    brandCarrousel: PropTypes.array,
    bannerContactUs: PropTypes.object,
    subHeader: PropTypes.object,
    partnering: PropTypes.object
  })
}

export default Publishers
