import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@components/icon'
import { classNames, Breakpoints } from '@utils'
import styles from './partners.module.scss'

const Partners = props => {
  const { tag, title, items } = props

  const icons = items ? (
    <div className={styles.partners}>
      {
        items.map(item => <Icon key={item.ID} name={item.image} className={styles.partnersIcon} />)
      }
    </div>
  ) : null


  return (
    <div className={styles.container}>
      <Breakpoints>
      {breakpoint => {
        const dot = ['xs', 'sm'].includes(breakpoint)
        return !dot ?
          (
            <>
              <div className={styles.dot} />
              <Icon name="grid.png" className={styles.grid} />
            </>
          )
          :
          null
      }}
      </Breakpoints>
      <div className={styles.textWrapper}>
        {!!tag && <p className={classNames(styles.text, styles.textTag)}>{tag}</p>}
        {!!title && <h2 className={classNames(styles.text, styles.textTitle)}>{title}</h2>}
      </div>
      {icons}
    </div>
  )
}

Partners.propTypes = {
  tag: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array
}

export default Partners
